<template>
  <!-- 字典列表 -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-button
      v-checkbtn="isShowBtn(AUTH_BTN.dict_list_add)"
      style="margin-bottom: 20px"
      type="primary"
      icon="el-icon-plus"
      size="small"
      @click="$router.push({ name: 'dictManageAdd' })"
      >添加</el-button
    >
    <!-- <div class="btn-group">
      <div></div>
      <div>
        <el-button type="primary" icon="el-icon-plus" size="small" @click="goAdd">添加</el-button>
      </div>
    </div> -->
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="pageSize" />
  </div>
</template>

<script>
import { dictListApi, delDict } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: '字典名称',
    minWidth: '240',
    prop: 'name',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['name']}>
          {row['name']}
        </p>
      )
    }
  },
  {
    label: '字典code值',
    minWidth: '240',
    prop: 'key',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['key']}>
          {row['key']}
        </p>
      )
    }
  },
  {
    label: '备注',
    minWidth: '200',
    prop: 'remark',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['remark']}>
          {row['remark']}
        </p>
      )
    }
  },
  {
    label: '操作',
    fixed: 'right',
    width: '400',
    customRender(h, row) {
      return (
        <div>
          <el-button
            disabled={!this.isShowBtn(this.AUTH_BTN.dict_list_edit)}
            type="primary"
            size="mini"
            icon="el-icon-edit"
            onClick={() => this.$router.push({ name: 'dictManageEdit', params: { id: row['id'] || undefined } })}
          >
            编辑
          </el-button>
          <el-button
            disabled={!this.isShowBtn(this.AUTH_BTN.dict_list_del)}
            type="danger"
            size="mini"
            icon="el-icon-delete"
            onClick={() => this.goDel(row['id'])}
          >
            删除
          </el-button>
          <el-button
            disabled={!this.isShowBtn(this.AUTH_BTN.dict_list_add_value)}
            type="warning"
            size="mini"
            icon="el-icon-plus"
            onClick={() => this.goAddDictValue(row['key'], row['name'])}
          >
            添加字典值
          </el-button>
        </div>
      )
    }
  }
]
export default {
  mixins: [authBtnMixin],
  data() {
    return {
      columns,
      list: [],
      total: 0,
      currentPage: 1,
      pageSize: 20
    }
  },
  mounted() {
    this.getList()
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    }
  },
  methods: {
    async getList() {
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize
      }
      const res = await dictListApi(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    goDel(id) {
      this.$confirm('此操作将永久删除该字典, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delDict(id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    goAddDictValue(dict_key, dictName) {
      this.$router.push({ name: 'DictValueManage', query: { dict_key: dict_key || undefined, dictName: dictName || undefined } })
    },
    goRemove(id) {
      console.log(id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-width: 800px;
  /deep/.one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
  .el-table__body-wrapper {
    width: 100%;
  }
  .el-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /deep/.el-form-item {
      margin-bottom: 0;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
